import classNames from 'classnames';
import fetchJsonp from 'fetch-jsonp';
import Fuse from 'fuse.js';
// FIXME(06-23-2020) Having trouble with typing react-select so I'm leaving it without types for now
// @ts-ignore
import { capitalize } from 'lodash';
import isEqual from 'lodash/isEqual';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
// @ts-ignore
import ReactSelect, {
  AsyncCreatable,
  Creatable,
  Option as ReactSelectOption,
  OptionComponentProps as OptionProps,
} from 'react-select';
import defaultMenuRenderer from 'react-select/lib/utils/defaultMenuRenderer';
import { SelectStateDropdown } from '~/src/components/ClaimWorkflow/SelectStateDropdown';
import { Location } from '~/src/generatedX/graphql';
import { apolloClient } from '~/src/services/apollo';
import { formatPhoneNumber, stripEmojis } from '~/src/utils';

import { gql } from '@apollo/client';
import { IconEmojiRendererLazy } from '@assured/design-system/src/components/Icon/IconEmojiRendererLazy';
import IconFlatHighwayShield from '@assured/design-system/src/components/Icon/IconFlatHighwayShield';
import { HighwayExitType } from '@assured/design-system/src/components/LocationIndicator/LocationIndicator';
import {
  SelectGroups,
  type SelectOption,
} from '@assured/design-system/src/components/SelectDropdown/types';
import { conicGradient } from '@assured/design-system/src/lib/conicGradient';
import { LocationType as LocationObjectType } from '@assured/shared-types/Claim';
import { LocationFragment } from '@assured/shared-types/Location';
import { ExitNotListed } from '@assured/shared-types/Location/highwayExit';
import {
  makeAriaLabel,
  makeAriaLabeledBy,
  makeStepComponentFieldID,
} from '@assured/step-renderer/helpers/stringsUtils';
import {
  CustomSelectValue,
  Option,
} from '@assured/step-renderer/types/step-components/additional';
import { SelectSource } from '@assured/step-renderer/types/step-components/Select';

import { HighlightedText } from '@assured/design-system/src/components/HighlightedText';
import { useMobileDetect, useTenantConfig } from '../../hooks';
import { dataTestId } from '../../utilities/dataTestId';
import Dropdown from '../Dropdown';
import Icon from '../Icon';
import Overdrive from '../Overdrive';
import { ExitLocationList } from './ExitLocationList/ExitLocationList';
import Label from './Label';
import OtherModal from './OtherModal';
import { formatHighlightedTerms } from './Title';

import { useAbTestingVariant } from '@assured/design-system/src/hooks/useAbTestingVariant';

import STATES_OPTIONS from '@assured/design-system/src/components/SelectStateDropdown/options';
import type {
  SelectStepComponentSpec,
  StepComponentControlsErrorProps,
  StepComponentFC,
  StepComponentOtherValue,
  StepComponentSharedProps,
  StepComponentSourceValue,
} from '@assured/step-renderer';

type SelectExtendable = StepComponentSharedProps<
  SelectStepComponentSpec,
  CustomSelectValue
> &
  StepComponentControlsErrorProps &
  Partial<StepComponentOtherValue<string> & StepComponentSourceValue<string>>;

interface SelectProps extends SelectExtendable {
  overdriveField?: string;
  setTempValues: (obj: any) => void;
  tabIndex?: number;
}

const HighlightedOption = (props: OptionProps<SelectOption>) => {
  const inputValue = props?.inputValue || '';
  const optionText = props?.option?.label;

  return (
    <ReactSelectOption {...props}>
      <HighlightedText
        className="normal-case"
        classNameHighlighted="font-bold text-blue-600"
        input={optionText}
        term={inputValue}
      />
    </ReactSelectOption>
  );
};

// exported for unit testing purposes
export const getSelectComponent = (step_component: SelectStepComponentSpec) => {
  return step_component.disable_create
    ? ReactSelect
    : step_component.source
    ? [
        SelectSource.usStates as string,
        SelectSource.carYear,
        SelectSource.colors,
      ].includes(step_component.source)
      ? ReactSelect
      : AsyncCreatable
    : step_component.field &&
      ['color', 'otherColor'].includes(step_component.field)
    ? ReactSelect
    : Creatable;
};

const VEHICLE_MODELS_QUERY = gql`
  query SearchVehicleModels($make: String!) {
    searchVehicleModels(make: $make) {
      model
    }
  }
`;

const US_STATE_CITIES_QUERY = gql`
  query SearchStateCities($state: String!) {
    searchStateCities(state: $state) {
      cities
    }
  }
`;

const US_STATE_HIGHWAYS_QUERY = gql`
  query SearchStateHighways($state: String!) {
    searchStateHighways(state: $state) {
      highways {
        id
        name
        directions
      }
    }
  }
`;

type HighwayExitLocationOption = {
  label: string;
  value: string;
  sublabel?: string;
  group?: string;
  location?: LocationObjectType;
};

const HIGHWAY_NOT_LISTED_LABEL = 'Highway not listed';

const filterByOptionLabel =
  (filter: string) => (o: Option<CustomSelectValue>) => {
    if (!o.label) {
      return false;
    }

    const label = o.label.toString();

    // Filter down to matching entries _except_ "Highway not listed"
    // even if it matches the search text.
    return (
      label !== HIGHWAY_NOT_LISTED_LABEL &&
      label.toLowerCase().includes(filter.toLowerCase())
    );
  };

const Select: StepComponentFC<SelectProps> = ({
  className,
  error,
  otherValue,
  overdriveField,
  primaryValue,
  setTempValues,
  showErrorMessages,
  sourceValue,
  step_component,
  tabIndex = 0,
  updateValue,
}) => {
  const tenantConfig = useTenantConfig();
  const { isMobile } = useMobileDetect();
  const [showOtherInput, setShowOtherInput] = useState<boolean>(false);
  const history = useHistory();

  const enableImprovedClassicStateDropdown = useAbTestingVariant(
    'eng-8734-enable-state-abbreviations',
    false,
  );

  // We track the last user-selected label in case there are multiple buttons with
  // the same value; this way we always know which specific one to highlight.
  const [lastSelectedLabel, setLastSelectedLabel] = useState<string | null>(
    null,
  );

  const refs = useRef<Record<string | number | any, Overdrive | null>>({});
  const forceUpdateOverdrive = () => {
    Object.values(refs.current).forEach(r => r && r.onHide());
    refs.current = {};
  };

  const creatableRef = useRef<Creatable<React.ReactElement> | null>(null);

  const tempSelectValue = useRef<Record<string, any> | null>(null);

  const [locationSearchValue, setLocationSearchValue] = useState<string>('');
  const fuse = useMemo(() => {
    return new Fuse<HighwayExitLocationOption>([], {
      keys: ['label', 'sublabel', 'value'],
      includeScore: true,
      minMatchCharLength: 1,
      threshold: 0.3,
      useExtendedSearch: true,
    });
  }, []);

  const results = useMemo(() => {
    if (!locationSearchValue) {
      return step_component.options as HighwayExitLocationOption[];
    }

    fuse?.setCollection?.(
      'value' in
        ((step_component.options?.[0] || {}) as HighwayExitLocationOption)
        ? (step_component.options as HighwayExitLocationOption[])
        : step_component.options?.flatMap(o => (o as SelectGroups).options) ??
            [],
    );

    const extendedSearchInput = `="${ExitNotListed.id}" | ${locationSearchValue}`;
    const matches = fuse.search(extendedSearchInput);
    const matchedOptions = matches.map(match => match.item);
    return matchedOptions;
  }, [fuse, step_component.options, locationSearchValue]);

  // react-select can accept a custom function for searching through the
  // dropdown's options when matching for auto-complete. If specialized logic is
  // required (such as always including the "Highway not listed" option for US
  // State Highways at the end of the autocomplete search results), provide the
  // override function here.
  const customFilterOptionsFn = useMemo(() => {
    if (
      step_component.source !== SelectSource.highwaysInState &&
      step_component.source !== SelectSource.usStates
    ) {
      return undefined;
    }

    if (step_component.source === SelectSource.usStates) {
      if (!enableImprovedClassicStateDropdown) {
        return undefined;
      }

      return (options: Option<CustomSelectValue>[], queryText: string) => {
        const query = queryText.toLowerCase();

        const included: Set<string> = new Set();

        const startsWith = options.filter(option => {
          const include =
            option.label?.toLowerCase().startsWith(query) || // label starts with the state abbreviation
            option.value?.toLowerCase().startsWith(query); // value starts with the full name of the state

          if (include) {
            included.add(option.label);
          }

          return include;
        });

        const includes = options.filter(option => {
          return (
            option.label?.toLowerCase().includes(query) &&
            !included.has(option.label)
          );
        });

        return [...startsWith, ...includes];
      };
    }

    // us-state-highways case
    return (options: Option<CustomSelectValue>[], filter: string) => {
      // Custom search-filter function for popping up auto-complete
      // entries in the us-state-highways case. The backend is
      // always including a special "Highway not listed" option and
      // we always want that to match and be included in the
      // autocomplete options.
      let returnedOptions = options.filter(filterByOptionLabel(filter));

      if (!returnedOptions.length) {
        const filterNumbersOnly = filter.replaceAll(/[^\d]/g, '');

        returnedOptions = options.filter(
          filterByOptionLabel(filterNumbersOnly),
        );
      }

      // Unconditionally add the "Highway not listed" option at the end.
      return returnedOptions.concat(
        options.filter(o => o.label === HIGHWAY_NOT_LISTED_LABEL),
      );
    };
  }, [step_component.source]);

  useEffect(() => {
    if (creatableRef.current && !step_component.no_scroll_into_view) {
      // FIXME(06-18-2020)confused how this is working
      const node = (creatableRef.current as any).select?.wrapper;
      if (node) {
        window.scroll({
          left: 0,
          top: (node as HTMLElement).getBoundingClientRect().y - 100,
          behavior: 'smooth',
        });
      }
    }
  }, [creatableRef]);

  const convertToKey = (v: CustomSelectValue): number | string | any => {
    return v;
  };

  const undefinedGuard = (
    v: CustomSelectValue | undefined,
  ): CustomSelectValue => {
    return typeof v !== 'undefined' ? v : null;
  };

  const wrapChild = (
    content: React.ReactNode,
    v: CustomSelectValue,
    props: Record<string, any>,
  ) =>
    overdriveField ? (
      <Overdrive
        key={convertToKey(v)}
        id={`${overdriveField}-${v}`}
        duration={400}
        ref={r => {
          refs.current[convertToKey(v)] = r;
        }}
        {...props}
      >
        {content}
      </Overdrive>
    ) : (
      content
    );

  const currentValue = primaryValue;
  const existingOptions: Option<CustomSelectValue>[] =
    currentValue !== null
      ? [{ label: currentValue as any, value: currentValue }]
      : [];

  if (step_component.mode === 'mini_button_group') {
    return (
      <div
        className={classNames(
          className,
          'relative flex items-center justify-center -my-4',
        )}
        key={step_component.id}
      >
        {step_component.options?.map(({ label, value, testId }) => {
          return (
            <button
              data-testid={testId || dataTestId(`${value}`)}
              tabIndex={Number(tabIndex)}
              key={label}
              className={classNames(
                'btn text-xs px-2 py-2 mx-1 font-medium leading-3',
                currentValue === value ? 'btn-blue' : 'btn-subtle',
              )}
              onClick={() => {
                if (typeof value !== 'undefined') {
                  updateValue(step_component.field, value);
                }
              }}
            >
              {label}
            </button>
          );
        })}
      </div>
    );
  }
  if (step_component.mode === 'dropdown_basic') {
    return (
      <div
        className={classNames(className, 'relative')}
        key={step_component.id}
      >
        <Label step_component={step_component} />
        {wrapChild(
          <div className="absolute left-0 right-0 top-0 bottom-0" />,
          'dropdown_fixed',
          {},
        )}
        <Dropdown
          id={makeStepComponentFieldID(step_component)}
          placeholder={step_component.placeholder}
          ariaLabelledBy={makeAriaLabeledBy(step_component)}
          value={
            step_component.options?.find(o => o.value === currentValue)?.label
          }
          options={step_component.options?.map(o => o.label) || []}
          onChange={e => {
            const option = step_component.options?.find(
              o => o.label === e.target.value,
            );
            if (typeof option?.value !== 'undefined') {
              updateValue(step_component.field, option.value);
            }
          }}
        />
      </div>
    );
  }
  if (step_component.source === SelectSource.highwayExits) {
    const formattedResults =
      results
        ?.map(o => ({
          title: o.label,
          body: o.sublabel ?? '',
          id: o.value?.toString() ?? '',
          value: o.location!,
          locationIndicatorKey:
            {
              Exit: HighwayExitType.EXIT,
              Junction: HighwayExitType.JUNCTION_INTERSTATE,
              'Rest Area': HighwayExitType.REST_AREA,
              Turnout: HighwayExitType.TURNOUT,
              'State Border': HighwayExitType.BORDER,
              Toll: HighwayExitType.TOLL,
              'Welcome Center': HighwayExitType.WELCOME_CENTER,
            }[o.group ?? 'Exit'] ?? HighwayExitType.EXIT,
        }))
        ?.filter(o => !!o.id?.trim() && !!o.title?.trim()) ?? [];

    const selectedItem =
      formattedResults.find(
        o => o.value?.addressText === (currentValue as Location)?.addressText,
      ) ?? undefined;

    useEffect(() => {
      const existingHighwayExit =
        step_component.source === SelectSource.highwayExits &&
        (step_component.existing_value as LocationFragment)?.line1?.split(
          ',',
        )?.[0];

      if (existingHighwayExit) {
        setLocationSearchValue(existingHighwayExit);
      }
    }, []);
    const inputRef = useRef<HTMLInputElement>(null);

    return (
      <div className="flex flex-col gap-1">
        <input
          type="text"
          value={locationSearchValue}
          ref={inputRef}
          className={classNames('textbox w-full mb-5', {
            'opacity-0': !!selectedItem,
            'h-0': !!selectedItem,
            'h-[44px]': !selectedItem,
            'pointer-events-none': !!selectedItem,
            'opacity-100': !selectedItem,
          })}
          onChange={e => {
            setLocationSearchValue(e.target.value);
          }}
          placeholder="Search for an exit"
        />
        <ExitLocationList
          searchTerm={locationSearchValue}
          locations={formattedResults}
          onItemClick={item => {
            updateValue(step_component.field, item.value);
          }}
          onItemDeselect={() => {
            updateValue(step_component.field, null);
            setLocationSearchValue('');
            inputRef.current?.focus();
          }}
          selectedItem={selectedItem}
        />
      </div>
    );
  }
  if (step_component.mode === 'dropdown') {
    const SelectComponent = getSelectComponent(step_component);

    let options = step_component.options || [];

    if (step_component.source === SelectSource.usStates) {
      if (!enableImprovedClassicStateDropdown) {
        // prettier-ignore
        options = ["Alabama","Alaska","Arizona","Arkansas","California","Colorado","Connecticut","Delaware","District Of Columbia","Florida","Georgia","Hawaii","Idaho","Illinois","Indiana","Iowa","Kansas","Kentucky","Louisiana","Maine","Maryland","Massachusetts","Michigan","Minnesota","Mississippi","Missouri","Montana","Nebraska","Nevada","New Hampshire","New Jersey","New Mexico","New York","North Carolina","North Dakota","Ohio","Oklahoma","Oregon","Pennsylvania","Rhode Island","South Carolina","South Dakota","Tennessee","Texas","Utah","Vermont","Virginia","Washington","West Virginia","Wisconsin","Wyoming"]
          .map(v => ({ label: v, value: v }))
      } else {
        options = STATES_OPTIONS;
      }
    } else if (step_component.source === SelectSource.carYear) {
      const N_YEARS = 100;
      const currentYear = new Date().getFullYear() + 1; // include next model year
      options = [
        ...new Array(N_YEARS).fill(0).map((_, i) => currentYear - i),
      ].map(v => ({ label: v.toString(), value: v }));
    } else if (step_component.source === SelectSource.highwaysInState) {
      options = options.map(({ label, value }) => ({
        label,
        value: {
          label,
          [JSON.parse(step_component.field ?? '{"fields":[{"field": ""}]}')
            .fields[0].field]: value,
        },
      }));

      if (
        step_component.existing_value &&
        isEqual(step_component.existing_value, primaryValue)
      ) {
        updateValue(
          step_component.field,
          options.find(
            o => o.label === (step_component.existing_value as any).label,
          )?.value ?? null,
        );
      }
    } else if (step_component.source_items) {
      options = step_component.source_items.map(v => ({ label: v, value: v }));
    }

    const frontMatter =
      step_component.source === SelectSource.highwaysInState ? (
        <div
          className={classNames(
            'absolute top-[2px] left-[2px] z-[1] py-2 px-1 w-fit rounded-l-md bg-[#241F20] bg-[linear-gradient(180deg,rgba(255,255,255,0.16)_0%,rgba(217,217,217,0)_100%)]',
            {
              Shake: error,
            },
          )}
        >
          <IconFlatHighwayShield className="w-6 h-6" />
        </div>
      ) : null;

    const value =
      typeof primaryValue === 'undefined'
        ? step_component.existing_value
        : (primaryValue as any);

    const onChange = (v: { value: CustomSelectValue } | null) => {
      tempSelectValue.current = v;
      updateValue(
        step_component.field,
        v
          ? typeof v.value === 'string'
            ? stripEmojis(v.value)
            : v.value
          : null,
      );
    };

    options = options.concat(
      existingOptions.filter(e => !options.find(o => o.value === e.value)),
    );

    const autoFocus = !primaryValue && !step_component.no_scroll_into_view;

    const onBlur = () => {
      const selectedValue = tempSelectValue.current;
      if (selectedValue) {
        updateValue(
          step_component.field,
          typeof selectedValue.value === 'string'
            ? stripEmojis(selectedValue.value)
            : selectedValue.value,
        );
        // Note: Use of any here is because the type definitions for react-select are wrong.
        // We need to call selectValue in order to force the UI to update to show the selected
        // value instead of the input text.
        (creatableRef.current as any)?.selectValue?.(selectedValue);
        tempSelectValue.current = null;
      }
    };

    const menuRenderer = (props: {
      inputValue: string;
      focusedOption: Record<string, any>;
    }) => {
      // Show menu right away, or after typing 2 chars (or 1, below)
      if (
        step_component?.source &&
        !['car-year', 'colors', SelectSource.usStates].includes(
          step_component?.source,
        ) &&
        (!props.inputValue || props.inputValue.length < 2)
      ) {
        return null;
      }

      if (step_component?.source === SelectSource.usStates) {
        if (!props.inputValue) {
          return null;
        }

        if (enableImprovedClassicStateDropdown && props.inputValue.length < 1) {
          return null;
        }

        if (
          !enableImprovedClassicStateDropdown &&
          props.inputValue.length < 2
        ) {
          return null; // keep previous component behavior
        }
      }

      if (props.focusedOption && step_component?.field !== 'otherColor') {
        tempSelectValue.current = props.focusedOption;
      }

      // @ts-ignore
      return defaultMenuRenderer(props);
    };

    const loadOptions = step_component.source
      ? (
          input: string,
          cb: (
            inputString: string | null,
            obj: {
              complete?: boolean;
              options: {
                value: string | number;
                label: string | number;
              }[];
            },
          ) => void,
        ) => {
          if (step_component.source === SelectSource.carMake) {
            fetchJsonp(`https://www.carqueryapi.com/api/0.3/?cmd=getMakes`)
              .then(r => r.json())
              .then(d => {
                cb(null, {
                  options: (d.Makes || [])
                    .map((m: any) => ({
                      label: m.make_display,
                      value: m.make_display,
                    }))
                    .concat(existingOptions),
                  complete: true,
                });
              });
          } else if (
            step_component.source === SelectSource.carModel &&
            sourceValue
          ) {
            apolloClient
              .query({
                query: VEHICLE_MODELS_QUERY,
                variables: { make: sourceValue },
              })
              .then(res => {
                cb(null, {
                  options: res.data.searchVehicleModels
                    .map((m: { model: string }) => ({
                      label: m.model,
                      value: m.model,
                    }))
                    .concat(existingOptions),
                  complete: true,
                });
              })
              .catch(e => {
                console.error(`Failed to lookup models`, e);
                cb(null, { options: [], complete: true });
              });
          } else {
            cb(null, { options: [] });
          }
        }
      : undefined;

    const placeholder =
      step_component.placeholder ||
      (step_component.source === 'car-year'
        ? 'Start typing a year to select...'
        : 'Start typing to select...');

    const onInputKeyDown =
      step_component.source === 'car-year'
        ? (e: React.KeyboardEvent<HTMLInputElement>) => {
            // ignore non-numeric keys
            if (e.key.match(/^[^0-9]$/) && !e.metaKey) {
              e.preventDefault();
            }
          }
        : undefined;

    const classNameProp = classNames(
      'text-left',
      error && 'Shake border-red-500',
      {
        '[&_.Select-placeholder]:pl-[2.75rem_!important]':
          step_component.source === 'us-state-highways',
        '[&_.Select-input]:pl-[2.75rem_!important]':
          step_component.source === 'us-state-highways',
        '[&_.Select-value-label]:pl-[1.75rem_!important]':
          step_component.source === 'us-state-highways',
      },
    );

    // FUTURE: enableStatePickerV2 is a feature flag that is set in the backend, enableStatePickerV2 check can be removed once we validate the new state picker
    const useSelectStateDropdown =
      step_component.enableStatePickerV2 &&
      step_component.source === SelectSource.usStates;

    return (
      <div
        className={classNames(
          className,
          'relative mt-4',
          step_component.subtle_with_textfield && 'mt-6 -mb-4',
        )}
        style={
          step_component.narrow
            ? { maxWidth: 250, marginLeft: 'auto', marginRight: 'auto' }
            : {}
        }
        key={step_component.id}
        data-testid={dataTestId(step_component.field)}
      >
        {frontMatter ?? null}
        <Label step_component={step_component} />
        {wrapChild(
          <div className="absolute left-0 right-0 top-0 bottom-0" />,
          'dropdown',
          {},
        )}
        {!useSelectStateDropdown && (
          <SelectComponent
            aria-labelledby={makeAriaLabeledBy(step_component)}
            aria-label={makeAriaLabel(step_component)}
            id={makeStepComponentFieldID(step_component)}
            aria-invalid={!!error}
            ref={(r: Creatable<React.ReactElement>) => {
              creatableRef.current = r;
            }}
            optionComponent={
              (enableImprovedClassicStateDropdown &&
                step_component.source === SelectSource.usStates &&
                HighlightedOption) ||
              undefined
            }
            className={classNameProp}
            placeholder={placeholder}
            autoFocus={autoFocus}
            onChange={onChange}
            options={options}
            filterOptions={customFilterOptionsFn}
            showNewOptionAtTop={false}
            arrowRenderer={() => null}
            onBlurResetsInput={false}
            onCloseResetsInput={false}
            value={value} // having trouble setting as anything other than an array
            promptTextCreator={(label: string) => label}
            inputProps={{
              ...(step_component.source === 'car-year' && { pattern: '\\d*' }),
              ...((step_component.source !== undefined ||
                step_component?.options?.length) && {
                'aria-autocomplete': 'list',
              }),
            }}
            onInputKeyDown={onInputKeyDown}
            menuRenderer={menuRenderer}
            onBlur={onBlur}
            loadOptions={loadOptions}
          />
        )}
        {useSelectStateDropdown && (
          <SelectStateDropdown
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            autoFocus={autoFocus}
            hideLabel
            placeholder={
              placeholder /* unless we want the state picker default */
            }
          />
        )}
        {error && showErrorMessages ? (
          <div
            role="alert"
            aria-live="assertive"
            className="text-left error-message"
          >
            {error}
          </div>
        ) : null}
      </div>
    );
  }

  const isColor = step_component.mode === 'color';
  const isColorWithOtherFlow =
    isColor && step_component?.select_mode_use_color_other; // rm after

  type PhoneNumber = {
    label: string;
    value: string;
  };

  const parsePhoneNumber = (action: string, label: string): PhoneNumber => {
    const phoneNumber: PhoneNumber = {
      label: label || 'Give us a call',
      value: '',
    };

    if (action === 'call') {
      phoneNumber.value = tenantConfig?.phoneNumber || '';
    } else if (action === 'call_bypass_ivr') {
      phoneNumber.value =
        tenantConfig?.bypassIvrPhoneNumber || tenantConfig?.phoneNumber || '';
    } else if (action === 'call_glass') {
      phoneNumber.value =
        tenantConfig?.glassPhoneNumber || tenantConfig?.phoneNumber || '';
    } else if (action === 'call_after_hours') {
      // falling back to bypassIvr number is for backwards compatibility,
      // may need to be cleaned up at some point.
      phoneNumber.value =
        tenantConfig?.afterHoursPhoneNumber ||
        tenantConfig?.bypassIvrPhoneNumber ||
        tenantConfig?.phoneNumber ||
        '';
    } else if (action.indexOf('call:') === 0) {
      phoneNumber.value = action.substring(5);
    }

    if (!isMobile) {
      phoneNumber.label = `Call ${formatPhoneNumber(phoneNumber.value)}`;
    }

    return phoneNumber;
  };

  return (
    <div
      key={step_component.id}
      role="group"
      aria-labelledby={makeAriaLabeledBy(step_component)}
      className={classNames(
        className,
        step_component.mode === 'wide' && 'flex-col',
        !step_component.grid_by && step_component.options?.length === 3
          ? 'grid grid-cols-1 sm:flex flex-wrap justify-center'
          : step_component.grid_by === 3
          ? 'flex flex-wrap justify-center sm:grid sm:grid-cols-3 gap-4 sm:mt-4'
          : 'flex flex-wrap justify-center',
        'mt-2 -m-2',
        !isColorWithOtherFlow && 'pt-3',
        step_component.grid_by_desktop ? 'sm:grid sm:grid-cols-3 sm:-mx-4' : '',
      )}
      style={isColor ? { maxWidth: 400, margin: '0 auto' } : {}}
    >
      {isColorWithOtherFlow && (
        <div className="col-span-full w-full flex justify-center pb-2 pt-0.5 text-cool-gray-600">
          {primaryValue || lastSelectedLabel ? (
            <div className="border py-0.5 rounded-lg px-2">
              {capitalize(
                lastSelectedLabel ||
                  results?.find?.(r => r.value === primaryValue)?.label,
              )}
            </div>
          ) : (
            <div className="py-0.5 border border-transparent">
              Choose a color
            </div>
          )}
        </div>
      )}
      <OtherModal
        onUpdate={v => {
          step_component.other_field &&
            updateValue(step_component.other_field, v);
        }}
        onSave={() => {
          setShowOtherInput(false);
          step_component.other_option &&
            !step_component.other_value_wrapper &&
            updateValue(step_component.field, step_component.other_option);
        }}
        onCancel={() => {
          setShowOtherInput(false);
        }}
        showInput={showOtherInput}
        prompt={step_component.other_prompt || 'Something else'}
        valueWrapper={step_component.other_value_wrapper}
        value={otherValue}
      />
      {step_component.options?.map(
        ({
          value: optionValue,
          action,
          label,
          label_style,
          sublabel,
          icon,
          icon_emoji,
          icon_text,
          bigger_label,
          style,
          magic,
          testId,
        }) => {
          const onClick = () => {
            // These option values can be either strings or objects
            const isOther = isEqual(optionValue, step_component.other_option);

            if (action) {
              const [actionType, actionValue] = action.split(':');
              window.analytics?.track('Select Action', {
                action,
                actionType,
                actionValue,
                label,
                step_component_id: step_component.id,
              });
              const phoneNumber = parsePhoneNumber(action, label);

              if (action === '911') {
                window.open('tel:911', '_blank');
              } else if (action.indexOf('call') === 0) {
                window.open(`tel:${phoneNumber.value}`);
              } else if (action.indexOf('open:') === 0) {
                window.open(action.substring(5), '_blank', 'noreferrer');
              } else if (action.indexOf('mailto:') === 0) {
                window.open(action, '_blank');
              } else if (action === 'force:goBack') {
                history.goBack();
              } else {
                window.alert(`Dispatching action :: ${action}`);
              }
            } else if (isOther && !isColor) {
              setShowOtherInput(true);
            } else if (isColorWithOtherFlow && step_component.field) {
              setLastSelectedLabel(label);
              setTempValues({
                [step_component.field]: undefinedGuard(optionValue),
              });
            } else {
              forceUpdateOverdrive();
              updateValue(step_component.field, undefinedGuard(optionValue));
              setLastSelectedLabel(label);
            }
          };

          const accentOptions: Record<string, any> = {
            positive: {
              default:
                'border-green-200 bg-green-100 sm:hover:bg-green-100 sm:hover:border-green-300',
              selected: 'border-green-300',
              textTitle: 'text-green-600',
              textSubtitle: 'text-green-400',
            },
            negative: {
              default:
                'border-red-200 bg-red-100 sm:hover:bg-red-100 sm:hover:border-red-300',
              selected: 'border-red-300',
              textTitle: 'text-red-600',
              textSubtitle: 'text-red-400',
            },
            blue: {
              default: 'btn btn-blue',
              selected: '',
              textTitle: 'text-white',
              textSubtitle: 'text-white',
            },
          };
          const accent = style && accentOptions[style];

          if (action?.indexOf('call') === 0) {
            const phoneNumber = parsePhoneNumber(action, label);
            label = phoneNumber.label;
          }

          if (isColor) {
            const colorBase = `${optionValue}`.replace('#', '');

            // For Overdrive to work properly.
            const colorClass = `ClaimWorkflowColorButton-${colorBase}`;

            // Since these are rendered as <div> elements with a
            // background-color, as opposed to using images, we need to
            // indicate to screen-readers that these exist.

            // The WAI-ARIA format allows us to "typecast" this to an "img"
            // (via the "role" prop) and then use an "aria-label" to
            // describe the imagery to screen reader users.
            const colorName = label;

            return (
              <React.Fragment key={convertToKey(undefinedGuard(optionValue))}>
                <style>{`
                  .${colorClass} { background-color: ${optionValue}; }
                `}</style>
                {/*
                  Converting to a <button> allows for Enter/Return
                  confirmation, while adding tabIndex of "1" sets all of
                  the swatches at a uniform "level" to Tab over them
                  one-by-one. This unblocks both keyboard users and
                  screen-reader folks.
                */}

                {wrapChild(
                  <button
                    role="button"
                    aria-pressed={colorName === lastSelectedLabel}
                    aria-label={`Clickable vehicle swatch of the color "${colorName}"`}
                    tabIndex={tabIndex}
                    data-testid={testId || dataTestId(`${optionValue}`)}
                    className={classNames(
                      `ClaimWorkflowColorButton ${colorClass} rounded-full shadow-inner border-2 border-cool-gray-300 m-2 focus:shadow-outline hover:shadow-outline cursor-pointer`,
                      primaryValue
                        ? primaryValue === optionValue
                          ? ''
                          : 'opacity-25'
                        : '',
                      isColorWithOtherFlow &&
                        lastSelectedLabel === label &&
                        'shadow-outline',
                      {
                        [colorClass]: optionValue !== 'OTHER',
                      },
                    )}
                    style={{
                      backgroundImage:
                        optionValue === 'OTHER' ? conicGradient : '',
                    }}
                    onClick={onClick}
                  />,
                  undefinedGuard(optionValue),
                  {},
                )}
              </React.Fragment>
            );
          }
          const dtId =
            testId ||
            (typeof optionValue === 'string' || typeof optionValue === 'boolean'
              ? dataTestId(optionValue)
              : dataTestId(label));

          return wrapChild(
            <button
              key={JSON.stringify(optionValue)}
              aria-label={label}
              tabIndex={tabIndex}
              className={classNames(
                'flex-1 flex flex-col justify-center',
                magic ? 'btn btn-magic mt-4 py-4 mx-2' : 'select-btn',
                accent && `accented sm:hover:shadow ${accent.default}`,
                primaryValue === optionValue &&
                  (lastSelectedLabel === null || label === lastSelectedLabel)
                  ? accent
                    ? accent.selected
                    : `bg-blue-100 border-blue-300 hover:bg-blue-100`
                  : accent
                  ? ''
                  : 'bg-white',
                bigger_label && 'p-1 sm:p-3',
                step_component.grid_by === 3 && 'sm:m-0',
              )}
              style={
                step_component.grid_by
                  ? overdriveField
                    ? {}
                    : {
                        flexBasis: `${80 / step_component.grid_by}%`,
                      }
                  : {}
              }
              onClick={onClick}
              data-testid={testId || dtId}
            >
              {icon_emoji ? (
                <IconEmojiRendererLazy
                  className="w-20 h-20 p-5 mx-auto"
                  iconKey={icon_emoji}
                />
              ) : (
                <Icon icon={icon} text={icon_text} />
              )}
              <div
                className={classNames(
                  magic
                    ? 'text-center text-white w-full'
                    : 'text-cool-gray-600 text-lg',
                  accent && accent.textTitle,
                  bigger_label && 'text-2xl',
                  label_style === 'mono' && 'font-mono',
                )}
              >
                {formatHighlightedTerms(label)}
              </div>
              {sublabel ? (
                <div
                  className={classNames(
                    'text-cool-gray-600 text-sm mt-1',
                    accent && accent.textSubtitle,
                  )}
                >
                  {sublabel}
                </div>
              ) : null}
            </button>,
            undefinedGuard(optionValue),
            {
              style: step_component.grid_by
                ? {
                    display: 'flex',
                    flex: '1',
                    flexBasis: `${80 / step_component.grid_by}%`,
                  }
                : {},
            },
          );
        },
      )}
    </div>
  );
};

Select.stepConfig = {
  // Only control errors for the dropdown
  controlsError: props => props.step_component.mode === 'dropdown',
};

export default Select;
